<template>
  <div>
    <div v-if="!loading">
      <ul>
        <li data-anima="bottom" class="produtor mb-3">
          <div class="co-seller-item">
            <div class="info-co-seller">
              <div class="separacao-co-seller">
                <h5>{{ $t('seller.products.listagem_coseller.text_510') }}</h5>
                <p>Você</p>
              </div>

              <div class="separacao-co-seller">
                <span>{{ 100 - percentage_total }}%</span>
              </div>
            </div>
          </div>
          <div>✨</div>
        </li>
        <div v-if="lista_component.length">
          <li
            v-for="(item, index) in lista_component"
            :key="item.id"
            data-anima="bottom"
          >
            <div class="co-seller-item">
              <div class="info-co-seller">
                <div class="separacao-co-seller">
                  <h5>{{ item.user.name }}</h5>
                  <p>{{ getTypeCoSeller(item.type) }}</p>
                </div>

                <div class="separacao-co-seller">
                  <span>{{ item.percentage }}%</span>
                  <p>
                    {{ item.updated_at | moment }}
                  </p>
                </div>
              </div>
            </div>
            <img
              src="@/assets/img/icons/lixeira.svg"
              class="icon-lixeira"
              :id="'btn-remover-' + item.id"
              @click="removeCoSeller(index, item.id, item.user.name)"
            />
            <b-tooltip
              :target="'btn-remover-' + item.id"
              title="Remover"
              placement="top"
            />
          </li>
        </div>
        <b-row key="nao-encontrado" v-else>
          <p class="nao-encontrado">{{ $t('seller.products.listagem_coseller.text_512') }}</p>
        </b-row>
      </ul>
    </div>
    <div class="container-loading" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import { EventBus } from "@/main.js";

// services:);

import CoSellerService from "@/services/resources/CoSellerService";
const serviceCoSeller = CoSellerService.build();

export default {
  data() {
    return {
      loading: false,
      users: [],
      lista_component: [],
      percentage_total: 0,
    };
  },
  props: {
    lista: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  filters: {
    moment: function(date) {
      return moment(date).format("DD/MM/YY HH:ss");
    },
  },
  watch: {
    lista(value) {
      this.lista_component = value;
    },
  },
  methods: {
    sumPercentage() {
      this.percentage_total = this.lista_component.reduce((total, item) => {
        return total + item.percentage;
      }, 0);
    },
    // remove Coprodutor
    removeCoSeller(index_lista, id, name_coseller) {
      Vue.swal({
        title: this.$t('seller.products.listagem_coseller.text_513'),
        text: `${this.$t('seller.products.listagem_coseller.text_514')} ${name_coseller}?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: this.$t('seller.products.listagem_coseller.text_513'),
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let data = {
            id: id,
          };

          await serviceCoSeller
            .destroy(data)
            .then((response) => {
              if (response.success) {
                // atualizando a lista no front
                this.lista_component.splice(index_lista, 1);
                this.sumPercentage();

                this.$bvToast.toast(this.$t('seller.products.listagem_coseller.text_515'), {
                  title: this.$t('seller.products.listagem_coseller.text_1946'),
                  variant: "success",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
              } else {
                this.$bvToast.toast(this.$t('seller.products.listagem_coseller.text_516'), {
                  title: this.$t('seller.products.listagem_coseller.text_1946'),
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
              }
              this.$emit("deleted", name_coseller);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },

    getTypeCoSeller(type) {
      let type_format = "";
      switch (type) {
        case "ALL": {
          type_format = "Todos";
          break;
        }
        case "AFFILIATE": {
          type_format = "Afiliados";
          break;
        }
        case "NOT_AFFILIATE": {
          type_format = "Não Afiliados";
          break;
        }
        case "MANAGER_AFFILIATE": {
          type_format = "Gerente de Afiliado";
          break;
        }
      }
      return type_format;
    },
  },
  mounted() {
    // this.fetchUsers();
    this.lista_component = this.lista;

    this.sumPercentage();

    EventBus.$on("atualizaPorcentagem", () => {
      this.sumPercentage();
    });
  },
};
</script>

<style scoped>
p {
  margin: 0;
}
/* lista de entregas */
.lista-entrega h5 {
  margin-bottom: 20px;
  color: $black;
  font-weight: 600;
  font-size: 14px;
}
.lista-entrega ul,
.opcoes-entrega {
  display: flex;
  flex-direction: column;
}
.lista-entrega ul li,
.opcoes-entrega li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 30px;
  height: 70px;
  border-radius: 5px;
  background: rgba(247, 247, 247, 0.5);
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s;
}
.lista-entrega ul li.produtor {
  background: #00236320;
}

.lista-arquivos ul li {
  padding: 13px 20px;
  font-size: 13px;
}
.opcoes-entrega li {
  padding: 20px 30px;
  height: 80px;
}
.lista-entrega ul li div {
  display: flex;
  align-items: center;
  font-size: 15px;
  text-transform: capitalize;
}
.opcoes-entrega li .grid-content {
  display: grid;
  grid-template-columns: 120px 1fr;
  align-items: center;
}
.lista-entrega ul li div img {
  width: 14px;
  margin-right: 30px;
}
.opcoes-entrega li div img {
  width: 20px;
  margin-right: 30px;
  filter: invert(50%);
}
.item-upload {
  position: relative;
  padding: 40px;
  border: 2px dashed #ededf0;
  border-radius: 5px;
  text-align: center;
}
.item-upload:hover {
  background: #f7f7f7;
}
.item-upload h5 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}
.item-upload p {
  font-size: 13px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
#arquivo-entrega {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  height: 80px;
  cursor: pointer;
  opacity: 0;
}
.opcoes-entrega li .infos h5,
.grid-content h5 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}
.grid-content h5 {
  margin: 0;
}
.opcoes-entrega li .infos p {
  font-size: 13px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
.opcoes-entrega li div img.logo-entrega {
  width: 60px;
}
.lista-entrega ul {
  max-height: 400px;
  overflow: auto;
}
.lista-arquivos ul {
  max-height: initial;
  overflow: initial;
}
.lista-entrega ul li .icon-lixeira,
.lista-entrega ul li .icon-editar {
  width: 14px;
  filter: invert(50%);
}
.lista-entrega ul li .icon-editar {
  margin-right: 15px !important;
}
.lista-entrega ul li:hover,
.opcoes-entrega li:hover {
  font-weight: 600;
  background: #f7f7f7;
}

.icon-lixeira:hover,
.icon-editar:hover {
  filter: invert(0) !important;
}
.lista-entrega ul li + li,
.opcoes-entrega li + li {
  margin-top: 10px;
}
/* // co-seller */
.co-seller-item {
  width: 90%;
}
.info-co-seller {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.info-co-seller h5 {
  margin: 0;
}
.info-co-seller .separacao-co-seller {
  display: block;
}
.info-co-seller .separacao-co-seller:last-child {
  text-align: right;
}
.info-co-seller .separacao-co-seller h5 {
  color: #333;
}
.info-co-seller .separacao-co-seller p {
  margin-top: 5px;
}
.produtor .separacao-co-seller h5,
.produtor .separacao-co-seller p,
.produtor .separacao-co-seller span {
  color: $black;
}
.produtor:hover {
  background: #00236320 !important;
}
</style>
